const navbar = require('./navbar')
const eprocMain = require('./eproc.main')
const menu = require('./menu.js')
const acessibility = require('./acessibilidade.js')
import toast from '#eproc/toast'

export default {
    ajax: require('./eproc/ajax.js'),
    hint: require('./hint.js'),
    main: eprocMain,
    menu: menu,
    modal: require('./eproc/modal'),
    navbar: navbar,
    tabs: require('./eproc/tabs'),
    toast: toast,
    init: () => {
        navbar.init()
        eprocMain.init()
        menu.init()
        acessibility.init()
    },
}