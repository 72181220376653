const debounce = require('lodash.debounce');
require('bootstrap');

const unorm = require('unorm');


// todo ver se alguma lib já essas refs

var KEYS = {
    ESC: 27,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    M: 77
};

var ID_MENU_PRINCIPAL = "main-menu";


function getUlDosFilhos(el) {
    return $($(el).children('ul')[0]);
}

function focarCampoBuscaMenu() {
    $("#sidebar-searchbox").focus();
}

var menu = {
    normalizar(str) {
        var normalized = unorm.nfd(str).replace(/[\u0300-\u036f]/g, "").toLowerCase();
        var regexp = new RegExp(/\s+/g);
        return normalized.replace(regexp, ' ').trim();
    },

    init: function () {

        function removerHighlights() {
            $(".menu-text-highlight").each(function () {
                var spanCorrespondente = $(this).parent('span');
                spanCorrespondente.text(spanCorrespondente.text());
            });
        }


        function filtrar(ul, termoBusca) {
            var match = false;

            $(ul).find('li').each(function () {
                var matchEmFilho = false;
                var liAtual = $(this);
                var hasFilhos = menu.possuiFilhos(liAtual);

                if (hasFilhos) {
                    var ulDosFilhos = getUlDosFilhos(this);
                    if (filtrar(ulDosFilhos, termoBusca)) {
                        matchEmFilho = true;
                    }
                }

                var liSearchTerm = getElementoComTexto(this).text();
                var matchLiAtual = menu.deuMatch(liSearchTerm, termoBusca);

                if (hasFilhos) {
                    if (!matchEmFilho) {
                        getUlDosFilhos(this).collapse('hide')
                    } else {
                        getUlDosFilhos(this).collapse('show');
                    }
                }

                if (matchLiAtual || matchEmFilho) {
                    if (matchLiAtual) {
                        match = true;
                        realcarMatch(liAtual, termoBusca);
                    }

                    exibirContainer(liAtual);
                } else {
                    esconderContainer(liAtual);
                }
            });

            return match;
        }


        function realcarMatch(li, filtro) {
            var spanText = getElementoComTexto(li);
            var textAtual = spanText.text();
            var filtroProcessado = menu.normalizar(filtro);
            var termoBuscaItemMenuNormalizado = menu.normalizar(textAtual);
            var idx = termoBuscaItemMenuNormalizado.indexOf(filtroProcessado);
            var filterLength = filtroProcessado.length;


            var preMatch = textAtual.substr(0, idx);
            var match = textAtual.substr(idx, filterLength);
            var posMatch = textAtual.substr(idx + filterLength, textAtual.length);

            var finalStr = preMatch
                + '<span class="menu-text-highlight">'
                + match
                + "</span>"
                + posMatch;
            spanText.html(finalStr);

        }


        function getElementoComTexto(el) {
            return $(el).children('a').children('.menu-item-text');
        }


        function esconderContainer(el) {
            $(el).hide();
        }

        function exibirContainer(el) {
            $(el).show();
        }


        function getTipoViewport(width) {
            return width <= TAMANHO_MIN_DESKTOP ? TIPO_MOBILE : TIPO_DESKTOP;
        }

        function resize() {
            var wrapper = $("#wrapper");
            var menuAbreAutomaticamente = wrapper.hasClass('abre-automaticamente');

            var width = $(this).width();
            var menuAberto = !wrapper.hasClass("toggled");
            var tipoViewportNovo = getTipoViewport(width);

            if (tipoViewportNovo !== viewport) {
                viewport = tipoViewportNovo;
                var isDesktop = (tipoViewportNovo === TIPO_DESKTOP);
                var isMobile = (tipoViewportNovo === TIPO_MOBILE);

                if (menuAberto && isMobile) {
                    contrairMenu();
                } else if (!menuAberto && isDesktop && menuAbreAutomaticamente) {
                    expandirMenu();
                }
            }
        }

        function handleBuscaMenu(e) {
            var termoBuscaNormalizado = menu.normalizar($(this).val());
            var termoBuscaAnterior = $(this).data('previous-value');
            if (termoBuscaAnterior === undefined) {
                termoBuscaAnterior = '';
            }

            if (e.keyCode === KEYS.ESC) {
                termoBuscaNormalizado = '';
                $(this).val(termoBuscaNormalizado);
                $('#main-menu .collapse').collapse('hide');
            }

            if (termoBuscaNormalizado !== termoBuscaAnterior) {
                removerHighlights();
                $(this).data('previous-value', termoBuscaNormalizado);
                filtrar($('#' + ID_MENU_PRINCIPAL), termoBuscaNormalizado);
            }
        }

        var TAMANHO_MIN_DESKTOP = 768;
        var TIPO_MOBILE = 0;
        var TIPO_DESKTOP = 1;
        var viewport = null;

        resize();

        $("#menu-btn").click(function (e) {
            e.preventDefault();

            if (!$("#wrapper").hasClass('touched')) {
                $("#wrapper").addClass('touched')
            }

            if (isMenuAberto()) {
                menu.fechar();
            } else {
                menu.abrir();
            }
        });

        $(window).resize(function () {
            resize();
        });

        $('#' + ID_MENU_PRINCIPAL + ' ul.collapse').on('show.bs.collapse', function (e) {
            e.stopPropagation();
            $(this).children('li').each(function () {
                exibirContainer($(this));
            })
        });

        $("#sidebar-searchbox").on('keydown', debounce(handleBuscaMenu, 100));

        if (PaginaEproc.isTelaPequena()) {
            $("#page-content-wrapper").on('click touchmove', function () {
                contrairMenu();
            });
        }

        function acoesTeclaAtalhoMenu(){
            focarCampoBuscaMenu()
            expandirMenu()
        }
        $(document).bind('keydown','Alt+M',acoesTeclaAtalhoMenu)

        $("#menu-btn").click(function () {
            if (PaginaEproc.isTelaPequena()) {
                window.setTimeout(function () {
                    focarCampoBuscaMenu();
                }, 200);
            } else {
                $("#menu-btn").blur();
            }
        });

        $("#sidebar-searchbox").on("keydown", function (e) {
            if (e.keyCode === KEYS.DOWN) { //down
                var primeiroVisivel = $('#' + ID_MENU_PRINCIPAL).find('li').filter(':visible').first().find('a').filter(":visible").first();
                primeiroVisivel.focus();
                e.preventDefault();
            }
        });
        $('#' + ID_MENU_PRINCIPAL).on("keydown", "a", handleKeydownItensMenu);

    },
    handleKeydownItensMenu: handleKeydownItensMenu,
    getItemApos: function (focusedLinkSelector) {
        var li = $(focusedLinkSelector).parent();
        var hasSubmenu = this.possuiFilhos(li);
        var isItemCollapsed = this.isItemCollapsed(li);
        var nextLi = null;
        var nextLink = null;

        if (hasSubmenu && !isItemCollapsed) {
            nextLi = li.children('ul').filter(":visible").first().children('li').filter(":visible").first();
        } else {
            nextLi = this.getProximoLi(li);
        }

        if (nextLi) {
            nextLink = this.getItemLink(nextLi);
        }

        return nextLink;
    },
    getItemAntesDe: function (focusedLinkSelector) {
        var aLi = $(focusedLinkSelector).parent();
        var prevLi = aLi.prevAll().filter(":visible").first();
        var possuiIrmaoAnterior = prevLi.length > 0;
        var prevLiA = null;
        if (possuiIrmaoAnterior) {
            var prevLastVisibleLI = this.getUltimoFilhoVisivelDe(prevLi);
            prevLiA = this.getItemLink(prevLastVisibleLI);
        } else {
            var ul = aLi.parent();
            var parentIsRoot = ul.attr('id') === ID_MENU_PRINCIPAL;

            if (parentIsRoot) {
                return null;
            } else {
                prevLiA = ul.prevAll('a').filter(":visible").first();
            }
        }

        return prevLiA;
    },
    getUltimoFilhoVisivelDe: function (li) {
        var hasSubmenu = this.possuiFilhos(li);
        var isItemCollapsed = this.isItemCollapsed(li);
        var lastListItem = null;

        if (hasSubmenu && !isItemCollapsed) {
            var lastChild = li.children('ul').filter(":visible").first().children('li').filter(":visible").last();
            lastListItem = this.getUltimoFilhoVisivelDe(lastChild);
        } else {
            lastListItem = li;
        }

        return lastListItem;
    },
    getLiPai: function (li) {
        var parentUl = li.parent();
        var parentIsRoot = parentUl.attr('id') === ID_MENU_PRINCIPAL;
        var parentLi = null;

        if (!parentIsRoot) {
            parentLi = parentUl.parent();
        }
        return parentLi;

    },
    possuiFilhos(li) {
        return li.hasClass('has-submenu');
    },
    getItemLink(li) {
        var prevA = li.find('a:first');
        return prevA;
    },
    isItemCollapsed(li) {
        var a = this.getItemLink(li);

        var collapsed = a.hasClass('collapsed');
        return collapsed;
    },
    /**
     *
     * @param li
     * @returns {el | null}
     */
    getProximoLi(li) {
        var proxLi = li.nextAll().filter(":visible").first();
        var possuiIrmaoProximo = proxLi.length > 0;
        var nextLi = null;

        if (!possuiIrmaoProximo) {

            var parentLi = this.getLiPai(li);

            if (parentLi !== null) {
                nextLi = this.getProximoLi(parentLi);
            }

        } else {
            nextLi = proxLi;
        }

        return nextLi;
    },
    deuMatch(str, filtro) {
        var match = false;
        var filtroProcessado = menu.normalizar(filtro);
        var termoBuscaItemMenuNormalizado = menu.normalizar(str);

        if (termoBuscaItemMenuNormalizado.indexOf(filtroProcessado) !== -1 || !filtroProcessado) {
            match = true;
        }

        return match;
    },
    fechar() {
        $("#wrapper").addClass("toggled");
        PaginaEproc.setCookieMenuAberto('N');

        $("#divInfraAreaGlobal").off("touchstart touchmove");
    },
    abrir() {
        $("#wrapper").removeClass("toggled");
        PaginaEproc.setCookieMenuAberto('S');

        var clientX, clientY;

        $("#sidebar-wrapper").on("touchstart", menu.touchstartHandler);
        $("#sidebar-wrapper").on("touchmove", menu.touchmoveHandler);
    },
    touchstartHandler: function (e) {
        clientX = e.originalEvent.touches[0].clientX;
        clientY = e.originalEvent.touches[0].clientY;
    },
    touchmoveHandler: function (e) {
        var deltaX, deltaY;

        deltaX = e.originalEvent.changedTouches[0].clientX - clientX;
        deltaY = e.originalEvent.changedTouches[0].clientY - clientY;

        if ((Math.abs(deltaX) > Math.abs(deltaY) + 20) && (deltaX < 0)) {
            menu.fechar();
        }
    }
};

function contrairMenu() {
    $('#wrapper').addClass('toggled');
}

function expandirMenu() {
    $('#wrapper').removeClass('toggled');
}


function handleKeydownItensMenu(e) {

    var aEl = e.target;
    var target = $(aEl);
    var li = target.parent();
    var key = e.keyCode;
    var proximoLink = null;


    switch (key) {
        case KEYS.LEFT:
            var possuiFilhos = menu.possuiFilhos(li);

            if (possuiFilhos) {
                if (!menu.isItemCollapsed(li)) {
                    var ulDosFilhos = getUlDosFilhos(li);
                    ulDosFilhos.collapse('hide');
                } else {
                    var parentLi = menu.getLiPai(li);
                    if (parentLi !== null) {
                        proximoLink = menu.getItemLink(parentLi);
                    }
                }
            } else {
                var parentLi = menu.getLiPai(li);
                if (parentLi !== null) {
                    proximoLink = menu.getItemLink(parentLi);
                }
            }
            break;

        case KEYS.UP:
            proximoLink = menu.getItemAntesDe(aEl);
            if (!proximoLink) {
                proximoLink = $("#sidebar-searchbox");
            }
            break;

        case KEYS.RIGHT:
            var possuiFilhos = menu.possuiFilhos(li);
            if (possuiFilhos) {
                if (menu.isItemCollapsed(li)) {
                    var ulDosFilhos = getUlDosFilhos(li);
                    ulDosFilhos.collapse('show');
                } else {
                    proximoLink = menu.getItemApos(aEl);
                }
            }
            break;

        case KEYS.DOWN:
            proximoLink = menu.getItemApos(aEl);
            break;

        case KEYS.ESC:
            focarCampoBuscaMenu();
            break;
    }

    if (proximoLink) {
        proximoLink.focus();
        e.preventDefault();
    }
}

module.exports = menu;
